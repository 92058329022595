import React, { useEffect, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import loadable from 'react-loadable-visibility/loadable-components';
import { routePatterns } from './lib/routerUtils';
import { LayoutFactory } from './components/Layouts/LayoutFactory';
import { useAppStateContext } from './lib/AppStateProvider';
import { NavBarContainer } from './components/Header/NavBarContainer';
import { HappinessMeterWidget, ScrollToTop, tealiumClickFunc } from '@dtcm/ui-components';
import { get } from './lib/getPropertyByPath';
import config from '../src/temp/config';
import { UniformContext } from '@uniformdev/react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { SitecorePersonalizationContextProvider } from '@uniformdev/personalize-react';
//import { doTracking } from '@uniformdev/optimize-js';
import { Tracker } from './Tracker';
import {getGlobalDictionaryTerm} from "@dtcm/ui-components/dist/utils";
import {SkipContentButtons} from "@dtcm/ui-components";

const COOKIES_ENABLED_COUNTRY_CODES =
'DE|AT|BE|HR|CY|CZ|DK|EE|FI|FR|GR|HU|IE|IT|LV|LT|LU|MT|NL|PL|PT|RO|SK|SI|ES|SE|GB|IS|LI|NO|BG';
function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M13.72 3.22l1.06 1.06L10.06 9l4.72 4.72-1.06 1.06L9 10.06l-4.72 4.72-1.06-1.06L7.94 9 3.22 4.28l1.06-1.06L9 7.94l4.72-4.72z"
      />
    </svg>
  );
}
/*const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  // @ts-ignore
  return parts && parts.length === 2 && parts.pop().split(';').shift();
}*/
const FooterContainer: any = loadable(
  () => import(/* webpackChunkName: "FooterContainer" */ './components/Footer/FooterContainer')
);

/*
  APP LAYOUT
  This is where the app's HTML "shell" should be defined.

  All routes share this root layout by default,
  but components added to inner placeholders are route-specific.
*/
const setDarkMode = (value: boolean = false, isDarkModeForThisPage: boolean) => {
  if (typeof window !== 'undefined')
    if (value && isDarkModeForThisPage) {
      document.querySelector('body')!.classList.add('dark');
    } else {
      document.querySelector('body')!.classList.remove('dark');
    }
};

export interface LayoutProps {
  route: {
    name: string;
    itemId: string;
  };
}

const Layout: React.FunctionComponent<LayoutProps> = (props: any) => {
  const { route } = props;
  const { appState, actions } = useAppStateContext();


  const sitecoreContext = appState.sitecoreContextData;
  sitecoreContext.itemId = get(route, 'itemId');
  const languageCode = get(appState, 'language.languageCode', '');
  const {isPersonalised} = appState;
  const appLocation = useLocation();
  const isEmptyLayout = get(appState, 'dictionary.viewBag.isEmptyLayout', false);
  // @ts-ignore
  const [mainHeight, setMainHeight] = React.useState<string>('600px');

  const alertBanner = get(sitecoreContext, 'configuration.alertBanner');
  const covidHeaderAnalytics = get(appState, 'dictionary.viewBag.navbar.covidHeaderAnalytics')

  const [showAlertBanner, setShowAlertBanner] = React.useState<boolean>(true);
  const [isInIframe, setIsInIframe] = React.useState<boolean>(true);

  const [currentRoute, setCurrentRoute] = React.useState<any>();
  //const [isTrackerInitialized, setIsTrackerInitialized] = React.useState(false);
  const ctx = useContext(UniformContext);
  const btnLabel: string = getGlobalDictionaryTerm(sitecoreContext, 'closeAnnouncementBannerLabel') || "Close announcement banner";

  useEffect(() => {
    if (appLocation.pathname.indexOf('thank-you') > -1) {
      setMainHeight('100px');
    }
    if (appLocation.search.indexOf('debug=true') <= -1) {
      // tslint:disable-next-line:no-empty
      window['console']['log'] = () => {};
    }
  }, [appLocation]);

  useEffect(() => {
    if (!ctx.logger) {
      ctx.logger = getLogger('context');
    }
    if (typeof window !== 'undefined') {
      const wind = window as any;
      const c3 =  wind.utag &&  wind.utag.gdpr && wind.utag.gdpr.getCookieValues().c3
      const consent = wind.utag &&  wind.utag.gdpr && wind.utag.gdpr.getCookieValues().consent
      if((consent && c3) || (wind.current_country != '' && COOKIES_ENABLED_COUNTRY_CODES.indexOf(wind.current_country) == -1)) {

        actions.setPersonalisation(true)
      } else {
        actions.setPersonalisation(false)

      }
      wind.personalisation = () => {
        console.log('SOMEONE CALL PERSONALISATION FROM UTAG')
        actions.setPersonalisation(true)

      }

      setIsInIframe(window.self !== window.top);

    }
  }, []);
  // const DUBAI_PRESENTS_TEMPLATES_LIST = [
  //   'Video Details Page',
  //   'Video Hub Page',
  //   'Episode Details Page',
  //   'Videos Listing Page',
  //   'Sections Intro Page',
  //   'Multiple Days Itinerary Page',
  // ];
  // const templateName = get(route, 'templateName');
  function getDarkModeSwitcherShow(placeholders: any): boolean {
    for (let key in placeholders) {
      const components = placeholders[key];
      for (let component of components) {
        if (component.params?.['Dark Mode Switcher Show'] == '1') {
          return true;
        }
        if (component.placeholders) {
          for (let innerKey in component.placeholders) {
            const innerComponents = component.placeholders[innerKey];
            for (let innerComponent of innerComponents) {
              if (innerComponent.params?.['Dark Mode Switcher Show'] == '1') {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
  

  useEffect(() => {
    setCurrentRoute(route);
  }, [route]);
  useEffect(() => {
    const LocalStorageDarkModeValue = appState.darkMode;

    const DarkModeJssParamValue =
      get(props, "route.placeholders") &&
      getDarkModeSwitcherShow(get(props, "route.placeholders"));

    LocalStorageDarkModeValue
      ? setDarkMode(true, DarkModeJssParamValue)
      : setDarkMode(false, DarkModeJssParamValue);
  }, [route, appState]);

  /*useEffect(() => {
    const shouldNotTrack =
      !currentRoute ||
      isTrackerInitialized !== true ||
      !sitecoreContext ||
      currentRoute.itemId !== route.itemId;
    if (shouldNotTrack) {
      return;
    }

    doTracking({
      source: 'sitecore',
      data: sitecoreContext,
      debug: true,
    });
  }, [currentRoute, isTrackerInitialized, sitecoreContext]);*/


  // TODO: this code conflicts with personalization during rehydration resulting in lost personalized content
  // needs further review

  // useEffect(() => {
  //   setTimeout(() => {
  //     setMainHeight('0');
  //   }, 1000);
  // }, []);

  return (
    <SitecorePersonalizationContextProvider
      contextData={sitecoreContext}
      personalizationMode="jss-esi"
      sitecoreSiteName={config.jssAppName}
      sitecoreApiKey={config.sitecoreApiKey}
      logger={getLogger('global')}
    >
      <React.Fragment>
        {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        {isPersonalised ? <Tracker route = {currentRoute} getLogger = {getLogger}/> :null}
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.PUBLIC_URL}/assets/styles/${
              appState.sitecoreContextData.rtl ? 'ar' : 'en'
            }.min.css?v=5938-2`}
          />
        </Helmet>


        <SkipContentButtons />
        <header>
          <div id="announcement-banner" />

          {alertBanner && alertBanner.text && showAlertBanner && !isInIframe ? (
            <div className={`alertBanner d-print-none theme-contrast ${alertBanner.theme}`}>
              <div className="container">
                <div className="module-group">
                  <div className="alertBanner__row">
                    <div
                      className="alertBanner__text"
                      onClick = {
                        () => {
                          tealiumClickFunc(covidHeaderAnalytics, window.location.href);
                        }
                      }
                      dangerouslySetInnerHTML={{
                        __html: get(appState, 'sitecoreContextData.configuration.alertBanner.text'),
                      }}
                    ></div>
                    <div className="alertBanner__button">
                      <button onClick={() => setShowAlertBanner(false)} aria-label={btnLabel}>
                        <span className="accessibility-hidden-text">{btnLabel}</span>
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <NavBarContainer />
        </header>
        <main
          className={`main overflowXClip`}
          tabIndex={-1}
          style={{
            minHeight: mainHeight,
          }}
        >
          {!isEmptyLayout && get(appState, 'sitecoreContextData.configuration.globalAnnouncementBanner.text') && (
            <div className="hero_image">
              <div className="hero_text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(appState, 'sitecoreContextData.configuration.globalAnnouncementBanner.text'),
                  }}
                ></div>
              </div>
            </div>
          )}
          <Placeholder name="esi-context" rendering={route} />
          <Switch>
            {routePatterns.map((routePattern) => (
              <Route key={routePattern} path={routePattern} component={LayoutFactory} />
            ))}
          </Switch>
          {!isEmptyLayout && <ScrollToTop offset={100} analytics={get(appState, 'analytics')} />}
          {languageCode === 'en' && <HappinessMeterWidget formUrl={`/HappinessMeterForm.aspx`} analytics={{}} />}
          <div id="event-remind-confirmations-wrapper"></div>
        </main>
        {/*
      We need to provide content for the loading placeholder used for the footer. This ensures
      that the dynamic loading script triggers for the Footer component. Without any content, it
      seems that the IntersectionObserver never intersects with the Footer component and therefore
      the dynamic loading script doesn't load the component.

      NOTE: most components will not need content in order for dynamic loading to work. The Footer
      seems to be more of an edge case.
      */}
        <FooterContainer />
      </React.Fragment>
    </SitecorePersonalizationContextProvider>
  );
};

function getLogger(name: string) {
  return {
    debug: (message: string, data: any) => {
      console.log(new Date().toISOString() + ` [DEBUG] [${name}] ` + message, data);
    },
    info: (message: string, data: any) => {
      console.log(new Date().toISOString() + ` [INFO]  [${name}] ` + message, data);
    },
    warn: (message: string, data: any) => {
      console.log(new Date().toISOString() + ` [WARN]  [${name}] ` + message, data);
    },
    error: (message: string, data: any) => {
      console.log(new Date().toISOString() + ` [ERROR] [${name}] ` + message, data);
    },
  };
}

export default Layout;
